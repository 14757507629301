<template>
  <b-container fluid> 
    <Overview :employee="employee" v-if="notEmptyObject(employee)" :next="next" :previous="previous"/>
  </b-container>
</template>

<script>
import Overview from './components/Overview';

export default {
    title: "Mitarbeiter bearbeiten",
    name: "EmployeeDetails",
    components: {
        Overview,
    },

    data() {
        return {
            employee: {},
            next: {},
            previous: {},
        }
    },

    methods:{
        nextEmployee()
        {
            this.$router.push({ name: 'employees.edit', params: { id: this.next.id } })
            this.getEmployee();
        },
        previousEmployee()
        {
            this.$router.push({ name: 'employees.edit', params: { id: this.previous.id } })
            this.getEmployee();
        },
        getEmployee(){
            this.axios
                .get("/employees/" + this.$route.params.id)
                .then((response) => {
                    this.employee = response.data.data;
                    this.next = response.data.next;
                    this.previous = response.data.previous;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created() {
        this.getEmployee();
    }
}
</script>

<style>

</style>